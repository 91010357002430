/**
 * Parallax Effect
 * (because jQuery.parallax sucks)
 * @author Micah Henning <micah@enove.co>
 * @license MIT
 * @method
 *   @param {Object} settings - Supports the following settings:
 *		{
 *			{Element} element - Element to parallax (should contain child image)
 *			{Int} distance    - Total movement permitted in terms of pixels
 * 								(the higher the number the faster the movement,
 * 								 i.e. the closer it will appear)
 * 			{Boolean} reverse - set true to move opposite the mouse
 *		}
 */
var parallax = function (settings) {
	'use strict';
	
	// Mutual suspicion
	if (! (settings instanceof Object) )
		throw new Error('Parallax: settings must be an Object.');
	if (!settings.element || ! (settings.element instanceof Element) )
		throw new Error('Parallax: settings.element must be a DOM Element.');
		
	// Cast to integer if necessary
	settings.distance = parseInt(settings.distance);
	if ( isNaN(settings.distance) )
		throw new Error('Parallax: settings.distance must be a number.');
	
	// Initalize parallax
	var initialize = function () {
		// Make sure proper CSS properties are in place
		settings.element.style.position   = 'fixed';
		
		// Set width and height to be equal to the body plus settings.distance
		// on all sides of the element
		settings.element.style.width 	  = document.body.offsetWidth  + 
			settings.distance * 2 + 'px';
		settings.element.style.height 	  = document.body.offsetHeight + 
			settings.distance * 2 + 'px';

		// Setting negative margins for top and left will center the element
		// relative to the body
		settings.element.style.margin 	  = -(settings.distance) + 'px 0 0 ' + 
			-(settings.distance) + 'px';
	};
	
	// Update on mouse movement
	document.body.addEventListener('mousemove', function (event) {
		// Get the midpoint of the body (which inherently is the midpoint of the image)
		var midpoint = [ document.body.offsetWidth / 2, 
						 document.body.offsetHeight / 2 ],
		
		// Reverse?
		// !! casts anything to Boolean
		// + casts Boolean to integer
		rev = [1, -1][+!!settings.reverse],
		
		// normalize the offset between -1 and 1 as a percentage of settings.distance
		// to move from the default position
		offset = [ (event.clientX - midpoint[0]) / midpoint[0] * rev,
				   (event.clientY - midpoint[1]) / midpoint[1] * rev ];
		
		// Update margins
		settings.element.style.marginLeft = 
			offset[0] * settings.distance - settings.distance + 'px';
		settings.element.style.marginTop  = 
			offset[1] * settings.distance - settings.distance + 'px';
	});
	
	// Update on resize
	window.addEventListener('resize', initialize);
	
	// Begin
	initialize();
};